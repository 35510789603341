import thunk from "redux-thunk";
import root from "../src/redux/reducer/index"
import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";

const initialState = {};
const middleware = [thunk];

const store = createStore(  root,  initialState,  composeWithDevTools(applyMiddleware(...middleware)));

export default store;

// import thunk from "redux-thunk";
// import { createStore, applyMiddleware } from "redux";
// import { composeWithDevTools } from "redux-devtools-extension";
// import { persistReducer, persistStore } from 'redux-persist';
// import storage from 'redux-persist/lib/storage';
// import root from "../src/redux/reducer/index";

// const initialState = {};
// const middleware = [thunk];

// const persistConfig = {
//   key: 'root',
//   storage,
//   whitelist: ['reducer1', 'reducer2']
// };

// const persistedReducer = persistReducer(persistConfig, root);

// const store = createStore(
//   persistedReducer,
//   initialState,
//   composeWithDevTools(applyMiddleware(...middleware))
// );

// const persistor = persistStore(store);

// export { store, persistor };
