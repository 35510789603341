import React, { useState } from 'react';
import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import { Button, Form, Input, Typography } from 'antd';
import { useDispatch } from 'react-redux';
import { fetchSpritual } from './redux/action/form';
import Swal from "sweetalert2";

const CardInput = (data) => {
    const { app } = data
    const [form] = Form.useForm();
    const stripe = useStripe();
    const elements = useElements();
    const dispatch = useDispatch();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (values) => {
        setLoading(true);
        if (!stripe || !elements) {
            setLoading(false);
            return;
        }
        const cardElement = elements.getElement(CardNumberElement);
        try {
            const { token, error } = await stripe.createToken(cardElement);
            if (error) {
                console.error('Error:', error.message);
                setLoading(false);
                setError(error.message);
                Swal.fire({
                    icon: "error",
                    title: `${error?.message}`,
                    showConfirmButton: false,
                    timer: 1500,
                });
                return;
            }
            if (token) {
                const successCb = (response) => {
                    form.resetFields();
                    Swal.fire({
                        icon: "success",
                        title: `${response?.message}`,
                        showConfirmButton: false,
                        timer: 1500,
                    });
                    setLoading(false);
                    setError(null);
                }
                const failureCb = (response) => {
                    Swal.fire({
                        icon: "error",
                        title: `${response?.message}`,
                        showConfirmButton: false,
                        timer: 1500,
                    });
                }
                const data = {
                    token: token?.id,
                    amount: values?.Donation_Amount
                }
                const url = `stripe/create_charge`;
                const method = "post"
                const fileUpload = false
                const secure = false // token check          
                const shouldDispatch = false  //redux store
                const dispatchType = null  //distpatch store
                dispatch(fetchSpritual(url, method, fileUpload, data, successCb, failureCb, secure, shouldDispatch, dispatchType, app))
            }
        } catch (error) {
            console.error('Error:', error);
            setLoading(false);
            setError('Something went wrong. Please try again later.');
        }
    };

    return (
        <Form className='w-100' form={form} onFinish={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            <Typography className='navTitle mb-2'> Donate Now </Typography>
            <label>Donation Amount</label>
            <Form.Item name="Donation_Amount" autoComplete="off" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}
                rules={[{ required: true, message: 'Please Enter Donation Amount!' }]} >
                <Input type='text' placeholder='0.00' className='input_box input__height' prefix={"$"}
                    style={{ backgroundColor: "#F1F1F1" }}
                    onKeyPress={(e) => {
                        const isValidInput = /^[0-9\b]+$/.test(e.key);
                        if (!isValidInput) {
                            e.preventDefault();
                        }
                    }} />
            </Form.Item>
            <Form.Item name="cardName"
                rules={[{ required: true, message: 'Please Enter Card Name!' }]}>
                <Input type='text' id="cardName" placeholder="Card Name" className='input_box input__height' style={{ backgroundColor: "#F1F1F1" }} />
            </Form.Item>
            <Form.Item name="cardno" style={{ backgroundColor: '#F1F1F1', paddingLeft: '1rem', borderRadius: '0.4rem' }}>
                <CardNumberElement options={{ style: { base: { fontSize: '16px', lineHeight: '52px', } }, showIcon: true }} />
            </Form.Item>
            <Form.Item name="exp" style={{ backgroundColor: '#F1F1F1', paddingLeft: '1rem', borderRadius: '0.4rem' }}>
                <CardExpiryElement options={{ style: { base: { fontSize: '16px', lineHeight: '52px', } } }} />
            </Form.Item>
            <Form.Item name="cv" style={{ backgroundColor: '#F1F1F1', paddingLeft: '1rem', borderRadius: '0.4rem' }}>
                <CardCvcElement options={{ style: { base: { fontSize: '16px', lineHeight: '52px', } } }} />
            </Form.Item>
            {error &&
                <Form.Item>
                    <p>{error}</p>
                </Form.Item>
            }
            <Form.Item className='d-flex justify-content-center '>
                <Button className={app=="Spiritual"?'auth_button':'auth_button_ew'} htmlType="submit" disabled={!stripe || loading} type='ghost'>
                    {loading ? 'Processing...' : `Process Payment`}
                </Button>
            </Form.Item>
        </Form>
    );
};

export default CardInput;