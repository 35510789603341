
import { baseUrlSG, baseUrlEW } from "../../contant";
import { apiCall } from "../../api";

export const fetchSpritual = (url, method, fileUpload, data, successCb, failureCb, secure, shouldDispatch, dispatchType, app) => async (dispatch) => {
    let config = {
        method: method,
        url: app == "Spiritual" ? `${baseUrlSG}${url}` : `${baseUrlEW}${url}`
    };
    if (data) {
        config.data = data
    }
    if (secure) {
        config.headers = {
            "Content-Type": "application/json",
        }
    } else {
        config.headers = { "Content-Type": "application/json" }
    }
    let response = await apiCall(config, dispatch);
    try {
        if (response?.data?.status) {
            if (shouldDispatch) {
                dispatch({
                    type: dispatchType,
                    payload: response?.data
                })
            }
            successCb(response?.data);
        } else {
            failureCb(response?.data);
        }
    } catch (error) {
        failureCb(response?.data);
    }
};