import './App.css';
import { Provider } from 'react-redux';
import store from './store';
import DonateForm from './Form';
import { ToastContainer } from 'react-toastify';

function App() {
  return (
    <>
      <Provider store={store}>
        <DonateForm />
      </Provider>
      <ToastContainer />
    </>
  );
}

export default App;