import React, { useEffect, useState } from 'react'
import { toast } from "react-toastify";
import { useDispatch } from 'react-redux';
import logo from "../src/images/donation.png"
import { Avatar } from 'antd';
import CardInput from './CardInput';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const DonateForm = () => {
    const key = "pk_live_51Ng8WZGj2XhwuqqsqAMU9BRy4NGFVZUvjQiQn2DSLcjeZgNFavj6EeaQ4wQ9oyrOgE2Pg3J44ciSSlU8mQiDvL3H00WJE9XxWa"
    const stripePromise = loadStripe(key);
    const [app, setApp] = useState("encouraging")

    useEffect(() => {
        let location = window.location.href
        console.log(location)
        if(location.indexOf('app=spiritualgifts') >= 0){
            setApp("Spiritual")
        }
    }, [])

    return (
        <>
        {console.log("type", window.location.href)}
        <div className="auth_page" onClick={() => { }}>
            <div className="card">
                <div className="authLogo mb-3">
                    <Avatar size={100} src={<img src={logo} alt="avatar" />} />
                </div>
                <Elements stripe={stripePromise}>
                    <CardInput app={app} />
                </Elements>
            </div>
        </div>
        </>
    )
}
export default DonateForm